import React from 'react';
import { useState} from 'react';
import { Container, Row, Col, Card, ButtonGroup, ToggleButton, Badge} from 'react-bootstrap';
import {initTheme} from '../../utils/theme'
import logo from '../../assets/logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import FormSelect from 'react-bootstrap/esm/FormSelect';
import i18n from '../../language/i18';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import Footer from '../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../redux/settingsReducer';
import './Pricing.css';
import '../../app/Landing.css';
import { useNavigate } from 'react-router-dom';
import SignInModal from '../landing/SingInModal';
import { testmode } from '../../utils/variablesProdTest';
import AlertTopBarPromoCodePricing from './AlertTopBarPromoCodePricing';
const Pricing = () => {
  // code to get settings values from redux
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const setSettings1 = (res)=>{dispatch(setSettings(res))}
  // code for getting the user from redux
  const user = useSelector((state) => state.user.user);
 // code for initializing the theme
  initTheme()
  
  
  const [language, setLanguage] = useState(settings.language);
  // hook for translation
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
    setSettings1({...settings, language: language})
  }, [language]);
  // google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  //Code for navigation
  const navigate = useNavigate()
  //detecting the currency
  const countryToCurrencyMap = {
    'US': 'USD',
    'EU': 'EUR',
    'GB': 'GBP',
    'MX': 'MXN',
    'AR': 'ARS',
    'BR': 'BRL',
    'JP': 'JPY',
    'KR': 'KRW',
    'CA': 'CAD',
    'NO': 'NOK',
    'CH': 'CHF',
    'AU': 'AUD',
    'CL': 'CLP',
    'PL': 'PLN',
    'HK': 'HKD',
    'CN': 'CNY',
    'ZA': 'ZAR',
    'IN': 'INR',
    'AT': 'EUR',
    'BE': 'EUR',
    'CY': 'EUR',
    'EE': 'EUR',
    'FI': 'EUR',
    'FR': 'EUR',
    'DE': 'EUR',
    'GR': 'EUR',
    'IE': 'EUR',
    'IT': 'EUR',
    'LV': 'EUR',
    'LT': 'EUR',
    'LU': 'EUR',
    'MT': 'EUR',
    'NL': 'EUR',
    'PT': 'EUR',
    'SK': 'EUR',
    'SI': 'EUR',
    'ES': 'EUR'
  };
  const getCurrencyFromLocale = (locale) => {
    const country = locale.split('-')[1];
    return countryToCurrencyMap[country] || 'EUR';
  };
  
  const formatPrice = (price, locale, currency) => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price);
  };
  
  const [currency, setCurrency] = useState('EUR'); // Default currency
  const [locale, setLocale] = useState('es-ES'); // Default locale, can be changed based on user settings

  useEffect(() => {
    const userLocale = navigator.language || navigator.userLanguage;
    const detectedCurrency = getCurrencyFromLocale(userLocale);
    setLocale(userLocale);
    setCurrency(detectedCurrency);
  }, []);
  //data for pricing

    const pricingData = 
      {
        premium:{
          link: {
            monthly: 
              testmode===true 
              ? 'https://buy.stripe.com/test_aEU8Ai3169vD01yaEK'
              : 'https://buy.stripe.com/4gw6qf55d22n1Q44gk',
            yearly:
              testmode===true 
              ? 'https://buy.stripe.com/test_9AQ7wedFKePXg0weV4'
              : 'https://buy.stripe.com/fZe4i755d9uPgKY28a',
          },
          priceId: {
            monthly: 
              testmode===true 
              ? 'price_1PUVrmB8UNTZlgmoHBglv2Xa'
              : 'price_1PWDyAB8UNTZlgmo5yQYoGeX',
            yearly:
              testmode===true 
              ? 'price_1PUnP5B8UNTZlgmopmLZ1Kjv'
              : 'price_1PWDxWB8UNTZlgmodsYE2eGo',
          },
          currencies: 
          {  "ARS": { "monthly": 13999.00, "yearly": 134399.00 },
          "AUD": { "monthly": 44.99, "yearly": 431.99 },
          "BRL": { "monthly": 89.99, "yearly": 863.99 },
          "CAD": { "monthly": 39.99, "yearly": 383.90 },
          "CHF": { "monthly": 34.99, "yearly": 335.00 },
          "CLP": { "monthly": 14999.00, "yearly": 143999.00 },
          "CNY": { "monthly": 119.00, "yearly": 1145.00 },
          "EUR": { "monthly": 29.99, "yearly": 287.90 },
          "GBP": { "monthly": 24.99, "yearly": 239.99 },
          "HKD": { "monthly": 189.00, "yearly": 1815.00 },
          "INR": { "monthly": 1300.00, "yearly": 12480.00 },
          "JPY": { "monthly": 3500.00, "yearly": 33599.00 },
          "KRW": { "monthly": 32000.00, "yearly": 307200.00 },
          "MXN": { "monthly": 299.99, "yearly": 2879.00 },
          "NOK": { "monthly": 339.00, "yearly": 3255.00 },
          "PLN": { "monthly": 109.00, "yearly": 1049.00 },
          "USD": { "monthly": 29.99, "yearly": 287.99 },
          "ZAR": { "monthly": 269.00, "yearly": 2582.00 }}
        },

        pro:{
          link: {
            monthly: 
              testmode===true 
              ? 'https://buy.stripe.com/test_28oaIqaty0Z701yeV2'
              : 'https://buy.stripe.com/28o6qfapx8qLamAcMP',
            yearly:
              testmode===true 
              ? 'https://buy.stripe.com/test_8wM4k2dFK5fn8y45kv'
              : 'https://buy.stripe.com/dR6aGv7dl6iD2U8145',
          },
          priceId: {
            monthly: 
              testmode===true 
              ? 'price_1PUnb0B8UNTZlgmoAoinFDiz'
              : 'price_1PWDxYB8UNTZlgmo870vVK7R',
            yearly:
              testmode===true 
              ? 'price_1PUnb0B8UNTZlgmoJ5KPDDM2'
              : 'price_1PWDxTB8UNTZlgmon43VkUKC',
          },
          currencies: {
            "ARS": { "monthly": 20000.00, "yearly": 168000.00 },
            "AUD": { "monthly": 42.99, "yearly": 361.00 },
            "BRL": { "monthly": 149.99, "yearly": 1259.99 },
            "CAD": { "monthly": 64.99, "yearly": 545.99 },
            "CHF": { "monthly": 49.99, "yearly": 419.99 },
            "CLP": { "monthly": 22999.00, "yearly": 193191.00 },
            "CNY": { "monthly": 169.00, "yearly": 1419.00 },
            "EUR": { "monthly": 49.99, "yearly": 419.99 },
            "GBP": { "monthly": 39.99, "yearly": 335.99 },
            "HKD": { "monthly": 309.00, "yearly": 2595.00 },
            "INR": { "monthly": 2200.00, "yearly": 18480.00 },
            "JPY": { "monthly": 5899.00, "yearly": 49550.00 },
            "KRW": { "monthly": 52000.00, "yearly": 436800.00 },
            "MXN": { "monthly": 469.99, "yearly": 3945.00 },
            "NOK": { "monthly": 559.00, "yearly": 4695.60 },
            "PLN": { "monthly": 199.00, "yearly": 1670.00 },
            "USD": { "monthly": 49.99, "yearly": 419.99 },
            "ZAR": { "monthly": 415.00, "yearly": 3486.00 }
          }
        }

      };  
  // showwing monthly or yearly prices
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const encodedEmail = user && encodeURIComponent(user.info.email);
  //data for the comparaison table
  const indexData = [
    { region: 'NNN',country: 'USA',index: 'DOWJONES',free: true,premium: true,pro: true },
    { region: 'NNN',country: 'USA',index: 'NASDAQ100',free: true,premium: true,pro: true },
    { region: 'NNN',country: 'USA',index: 'SP500',free: false,premium: true,pro: true },
    { region: 'NNN',country: 'CAN',index: 'TSX60',free: false,premium: true,pro: true },
    { region: 'NNN',country: 'MEX',index: 'BMVIPC',free: false,premium: true,pro: true },
    { region: 'EU',country: '',index: 'EUROSTOXX50',free: true,premium: true,pro: true },
    { region: 'EU',country: 'ESP',index: 'IBEX35',free: true,premium: true,pro: true },
    { region: 'EU',country: 'FRA',index: 'CAC40',free: true,premium: true,pro: true },
    { region: 'EU',country: 'DEU',index: 'DAX40',free: true,premium: true,pro: true },
    { region: 'EU',country: 'SWE',index: 'OMXS30',free: false,premium: true,pro: true },
    { region: 'EU',country: 'ITA',index: 'FTSEMIB',free: false,premium: true,pro: true },
    { region: 'EU',country: 'PRT',index: 'PSI20',free: false,premium: true,pro: true },
    { region: 'EU',country: 'NLD',index: 'AEX25',free: false,premium: true,pro: true },
    { region: 'EU',country: 'BEL',index: 'BEL20',free: false,premium: true,pro: true },
    { region: 'EU',country: 'IRL',index: 'ISEQ20',free: false,premium: false,pro: true },
    { region: 'EU',country: 'DNK',index: 'OMXC25',free: false,premium: false,pro: true },
    { region: 'EU',country: 'FIN',index: 'OMXH25',free: false,premium: false,pro: true },
    { region: 'EEE',country: 'CHE',index: 'SMI',free: true,premium: true,pro: true },
    { region: 'EEE',country: 'GBR',index: 'FTSE100',free: false,premium: true,pro: true },
    { region: 'EEE',country: 'NOR',index: 'OBXO25',free: false,premium: false,pro: true },
    { region: 'ABB',country: 'JPN',index: 'NIKKEI225',free: true,premium: true,pro: true },
    { region: 'ABB',country: 'KOR',index: 'KOSPI',free: false,premium: true,pro: true },
    { region: 'ABB',country: 'IND',index: 'NIFTY50',free: false,premium: true,pro: true },
    { region: 'ABB',country: 'HKG',index: 'HSI50',free: false,premium: false,pro: true },
    { region: 'ABB',country: 'SGP',index: 'STI',free: false,premium: false,pro: true },
    { region: 'UUU',country: 'AUS',index: 'ASX50',free: false,premium: false,pro: true },
    { region: 'SRR',country: 'ARG',index: 'MERVAL',free: true,premium: true,pro: true },
    { region: 'SRR',country: 'BRA',index: 'IBOVESPA',free: false,premium: true,pro: true },
    { region: 'SRR',country: 'CHL',index: 'CLXIPSA',free: false,premium: false,pro: true },
    { region: 'FFF',country: 'ZAF',index: 'SA40',free: false,premium: false,pro: true }
  ];
   
    
  const groupedIndexData = indexData.reduce((acc, curr) => {
    const { region } = curr;
    if (!acc[region]) {
      acc[region] = [];
    }
    acc[region].push(curr);
    return acc;
  }, {});

  const CheckSimbol = ()=>{
    return(
      <div>
      <svg focusable="false" fill="#f0c136" color="#1a1a1a" fill-opacity="0.8" height="12px" viewBox="0 0 16 16" width="12px"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fill-rule="evenodd"></path></svg>
      </div>
    )
  }
  const PricingElement = (element)=>{
    const price=pricingData[element].currencies[currency][billingPeriod];
    const discount = {premium:0.8, pro:0.7}
    return(
      <Col xs='12' lg='4' className='mt-4 mt-lg-0' >
        
        <Card  className={'me-0 border-2  p-4 rounded-4  plan h-100 '+ (element==='premium' && 'card-premium')}>
          <div style={{height:'55px'}}>
            {element==='premium' &&
              <Badge className='m-2 mt-0'>{t('pricing.premium_badge')}</Badge>}
            {billingPeriod==='yearly' &&
              <Badge className='m-2 mt-0'>{t('pricing.saving1')+ formatPrice((price/discount[element])-price, locale, currency) + t('pricing.saving2')}</Badge>}
              {/* <Badge className='m-2 mt-0'>{t('pricing.days_free')}</Badge> */}
          </div>
          <div className='text-center'>
          <div className='fs-5 text-start plan-text'>{t('pricing.'+element+'_title')}</div>
          <div style={{height:'90px'}} className='fs-2 text-start pt-4 fw-bold'> {formatPrice(price, locale, currency)}</div>
          <a href={pricingData[element].link[billingPeriod]+'?prefilled_email='+encodedEmail+'&client_reference_id='+user.sub}>
          <button >{t('pricing.subscribe')}</button>
          </a>
          <div style={{'fontSize':'14px'}} className='mt-3 text-start'>
              <div className='mt-1'><div>{t('pricing.thisIncludes')}</div> </div>
              <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.'+element+'_row1')}</div> </div>
              <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.'+element+'_row2')}</div> </div>
              <div className='d-flex mt-1'>
                <CheckSimbol />
                <div className='ms-3'>
                  {t('pricing.'+ element+ '_row3')}
                    <div> {'⦁ ' + t('pricing.'+ element+ '_row3_1')}</div>
                    <div> {'⦁ ' + t('pricing.'+ element+ '_row3_2')}</div>
                    <div> {'⦁ ' + t('pricing.'+ element+ '_row3_3')}</div>
                    <div> {'⦁ ' + t('pricing.'+ element+ '_row3_4')}</div>
                    <div> {'⦁ ' + t('pricing.'+ element+ '_row3_5')}</div>
                </div>
              </div>
              <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.'+element+'_row4')}</div> </div>
              <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.'+element+'_row5')}</div> </div>
              <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.'+element+'_row6')}</div> </div>
          </div>
          </div>
        </Card>
      </Col>
    )
  }


    return (
        <div>
        {/*  header */}
        <Navbar className="bg-dark pb-0 pt-0" sticky='top'>

          <Container className='mw-100 d-flex justify-content-between'>

          <Nav className="d-flex justify-content-start col">
            <Nav.Item>
              <Nav.Link className='text-light p-0 me-2 d-flex' href='/todaysignals' >
                <img className= "mt-lg-0 mb-0 me-2" style={{filter: 'brightness(0) invert(1)'}} width="33" height="33" src={logo} loading="lazy"></img>
                  <div className='fw-bold d-none d-lg-flex align-items-center'>{t('header.todaysignals')}</div>
                </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav.Link href='/' className= "d-flex col justify-content-center">
          <img className= "mx-1" width="50" height="50" src={logo} loading="lazy"></img>
          <div className='fw-bold d-none d-lg-flex align-items-center text-light h3 m-0'>AtomicStonks</div>
          </Nav.Link>
            <div  className='d-flex me-2 col justify-content-end' >
                <FormSelect  style = {{width:"60px"}} onChange={(e)=>{setLanguage(e.target.value)}} size="sm" className="bg-dark text-light me-2 border-0" defaultValue={language}>
                <option value="en">En</option>
                <option value="es">Es</option>
                </FormSelect>
                <svg className="" width="30px" height="30px" fill="white" viewBox="0 0 16 16"  >
                </svg>
            </div>
            
          </Container>
          </Navbar>
     {/*  End of header */}
     {user===null &&
      <SignInModal show={true} onHide={null}></SignInModal> 
     }
     <AlertTopBarPromoCodePricing></AlertTopBarPromoCodePricing>
     
        <Container className='mt-5'>
         
          <Row className='pt-5 pb-3'>
            <div className="titulo mb-3 text-center" >
              <span>{t('pricing.title')}</span>
              <span className='resaltarTitulo'>{t('pricing.title2')}</span>
            </div>
            
          </Row>
          <Row>
            <div className="subtitle mb-4 mt-3 px-5">
              <span>{t('pricing.subtitle')}</span>
              <span className='subtitleAtention2'>{t('pricing.subtitle2')}</span>
            </div>
          </Row>
          <div className='text-center'>
            <ButtonGroup size='lg' >
              <ToggleButton
                key={1}
                id={1}
                type="radio"
                variant={billingPeriod === 'monthly'?'primary':'light'}
                className={billingPeriod === 'monthly'?'':'billing-not-selected'}
                name="radio"
                value='monthly'
                checked={billingPeriod === 'monthly'}
                onChange={(e) => setBillingPeriod(e.currentTarget.value)}
              >
                {t('pricing.button_monthly')}
              </ToggleButton>
              <ToggleButton
                key={2}
                id={2}
                type="radio"
                variant={billingPeriod === 'yearly'?'primary':'light'}
                className={billingPeriod === 'yearly'?'':'billing-not-selected'}
                name="radio"
                value='yearly'
                checked={billingPeriod === 'yearly'}
                onChange={(e) => setBillingPeriod(e.currentTarget.value)}
              >
                {t('pricing.button_yearly')}
              </ToggleButton>
            </ButtonGroup>
          </div>
          <Row className='pt-5'>
            <Col xs='12' lg='1'></Col>
            <Col xs='12' lg='3' >
              
              <Card className=' me-0 border-2 p-4 rounded-4  plan h-100'>
                <div style={{height:'55px'}}></div>
                <div className='text-center'>
                <div className='fs-5 text-start plan-text'>{t('pricing.free_title')}</div>
                <div style={{height:'90px'}} className='fs-2 text-start pt-4 fw-bold'> {t('pricing.itsFree')}</div>
                <button onClick={()=>(navigate('/todaysignals'))}>{t('pricing.free_button')}</button>
                <div style={{'fontSize':'14px'}} className='mt-3 text-start'>
                    <div className='mt-1'><div>{t('pricing.thisIncludes')}</div> </div>
                    <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.row1')}</div> </div>
                    <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.row2')}</div> </div>
                    <div className='d-flex mt-1'>
                      <CheckSimbol />
                      <div className='ms-3'>
                        {t('pricing.row3')}
                          <div> {'⦁ ' + t('pricing.row3_1')}</div>
                          <div> {'⦁ ' + t('pricing.row3_2')}</div>
                          <div> {'⦁ ' + t('pricing.row3_3')}</div>
                          <div> {'⦁ ' + t('pricing.row3_4')}</div>
                      </div>
                    </div>
                    <div className='d-flex mt-1'><CheckSimbol/> <div className='ms-3'>{t('pricing.row4')}</div> </div>
                </div>
                </div>
              </Card>
            </Col>
            {user && Object.keys(pricingData).map((e)=>PricingElement(e))
            }
          </Row>
          <Container className='d-flex justify-content-center mb-5'>
            <table className='mt-5  tbl-container'>
              <thead className='tbl-header'>
                <tr>
                  <th>{t('pricing.region')}</th>
                  <th>{t('pricing.country')}</th>
                  <th>{t('pricing.index')}</th>
                  <th className='text-center'>Free</th>
                  <th className='text-center'>Premium</th>
                  <th className='text-center'>Pro</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedIndexData).map((region, regionIndex) => {
                  const regionData = groupedIndexData[region];
                  return regionData.map((data, index) => (
                    <tr>
                      {index === 0 && (
                        <td rowSpan={regionData.length}>{t('data.continent.'+region)}</td>
                      )}
                      <td>{data.country==='' ? '': t('data.country.'+data.country)}</td>
                      <td>{t('data.indexes.'+data.index)}</td>
                      <td className='text-center fw-bolder'>{data.free ? <div style={{color:'green'}}>✔</div> : '✖'}</td>
                      <td className='text-center fw-bolder'>{data.premium ?  <div style={{color:'green'}}>✔</div> : '✖'}</td>
                      <td className='text-center fw-bolder'>{data.pro ?  <div style={{color:'green'}}>✔</div> : '✖'}</td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </Container>
        </Container>
      <Footer className="mt-5"></Footer>
      </div>
    );
  };

export default Pricing;