import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {BsCaretDown} from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
function FilterSection({k, options, configureSelection}) {
    const path = useLocation().pathname;
    // hook for translation
    const { i18n, t } = useTranslation();
    //get user from redux
    const user = useSelector((state) => state.user.user);
    const [allSelected, setAllSelected]=useState(true);
    const onChangeSelection = (e) =>   {
      configureSelection(k,e.target.name, e.target.checked);
    }
    const selectAll = (e) =>{
      setAllSelected(e.target.checked);
      Object.keys(options).map((s)=>{
        configureSelection(k,s, e.target.checked)
      });
    }
    const [open, setOpen]=useState(false);
  return (
    <div className='m-2 mt-4'>
      <Button  variant='deafult' className='border-0 border-bottom border-secondary rounded-0 bg-transparent fw-bold container-fluid text-start' 
              onClick={() => setOpen(!open)}>
        <BsCaretDown className='text-primary me-2 mb-0 h4 fw-bold'/>{t('tablah.' + k)}
        
      </Button>
      <Collapse in ={open}>
        <div >
        <div className='d-flex flex-row'>
          <div  className='m-1 p-1 pe-2 border rounded border-secondary'>
            <Form.Check onChange={selectAll}  checked={allSelected} id={k} label={t('offcanvas.filter_select_all')} className='fw-bold '/>
          </div>
          </div>
        <div className='d-flex flex-wrap'>
              {Object.keys(options).map(s => (
                  <div  className = 'm-2 p-1 border rounded border-secondary'>
                    <Form.Check  onChange={onChangeSelection}  checked={options[s]}  name={s} id={s} label={t('data.' + k+'.'+s)}/>
                    </div>
              ))}
              { user.subscription==='free' && path ==='/todaysignals' && k ==='strategy' &&
                 <OverlayTrigger  placement="top" delay={{ show: 100, hide: 100 }}
                 overlay={(props) => (
                 <Tooltip id="button-tooltip"
                     {...props}
                 >
                     {t('offcanvas.filter_getWithPremium')}
                 </Tooltip>
                 )}
>
                   <div  className = 'm-2 p-1 border rounded border-secondary'>
                   <Form.Check disabled  onChange={onChangeSelection}  checked={false}  name={'macd_ema'} id={'macd_ema'} label={t('data.' + 'strategy'+'.'+'macd_ema')}/>
                   </div>
                 </OverlayTrigger>
              }
        </div>
        </div>
      </Collapse>
    </div>
  );
}

export default FilterSection;