import { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SignInModal from './SingInModal';
import { useSelector } from 'react-redux';
import '../../app/Landing.css'
function SignInButton(){
    // hook for translation
    const { i18n, t } = useTranslation();
    const [show, setShow] = useState(false);
    // functions to open moda
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (user===null){
            setShow(true);
        }
        else{
            navigate('/todaysignals')
        }
        }
    //function to navigate
    const navigate = useNavigate();
    //const handleShow2 = () => navigate('/todaysignals');
    // code for getting the user from redux
    const user = useSelector((state) => state.user.user);
    return(
    <div id="signInButton" className='d-flex justify-content-center'>
        <div onClick={handleShow} className="text-white fw-bold singInButton buttonContainerSubscribe" >
        
    <button className="buttonSubscribe">{t('landing.signalButton')}</button>
   {/*  <div className="triangle-left"></div>
    <div className="triangle-right"></div> */}

              
        </div>
        
        <SignInModal show={show} onHide={handleClose}></SignInModal>
    </div>
    )
}

export default SignInButton;